import HttpClient from './HttpClient';
import store from '../store';

export default class Subscription {

    constructor (subscription = null) {

        this.id = null;
        this.attribution = null,
        this.customer_id = null;
        this.converted_from_wibu_trials_users_id = null;
        this.cancel_reason = null;
        this.cancel_pending = false;
        this.cancellation_date = null;
        this.deactivate_reason = null;
        this.deactivation_date = null;
        this.free_convert_date = null;
        this.free_converted = null;
        this.free_period = null;
        this.free_trial_expiration = null;
        this.free_type = null;
        this.fs_data = null;
        this.fs_account = null;
        this.fs_dunning_stage = null;
        this.fs_status = null;
        this.fs_subscription_id = null;
        this.last_modified_date = null;
        this.pid = null;
        this.purchase = null;
        this.product_license_type = null;
        this.recent_billing_failed = false;
        this.sequence = null;
        this.status = null;
        this.will_be_deactivated_date = null;
        this.summarized_events = null;
        this.resubscribe_paths = null;
        this.dunning_stage = null;
        this.ga_id = null;
        this.is_in_dunning = false;
        this.subscription_type = null;
        this.term = null;
        this.smart_rebillings = [];
        this.purchase_by_id = null;
        this.pause_status = null;
        this.pause_term = null;

        if (subscription && typeof subscription === 'object') {
            for (const k in subscription) {
                this[k] = subscription[k];
            }
        }
    }

    /**
     * Instantiates a new subscription object with API response data
     */
    instantiate (data) {
        const populate = (d) => {
            for (let prop in d) {
                if (!Object.prototype.hasOwnProperty.call(this, prop)) {
                    continue;
                }
    
                this[prop] = d[prop];
            }
        }

        populate(data);
    }

    /**
     * Fetches an existing subscription
     * @param {number} id
     * @param {boolean} withEvents
     * @param {boolean} isFsSubId
     * @return {object}
     */
    static async fetch (id, withEvents = false, isFsSubId = false) {
        let payload = { path: `/laravel/rest/user/subscriptions/${id}` };

        if (withEvents) {
            payload['query'] = { events: true };
        }

        if (isFsSubId) {
            payload['query'] = payload['query'] ? { ...payload['query'], fsSubId: true } : { fsSubId: true };
        }

        const client = new HttpClient(payload);
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        }
        
        if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        if (!resp.data) {
            throw new Error(`Error fetching subscription with id ${id}`);
        }

        const { data } = resp;
        let sub = new Subscription(data);

        store.commit('setSubscription', sub);
        return sub;
    }

    /**
     * Fetches the current user's subscriptions
     */
    static async fetchSubscriptions (page = 1) {
        const client = new HttpClient({ path: '/laravel/rest/user/subscriptions', query: { page } });
        const resp = await client.get();

        if (!(resp && resp.status && resp.status === 200)) {
            throw new Error(resp.status);
        }

        if (!resp.data) {
            throw new Error('Error fetching subscriptions');
        }

        const { data, last_page, total } = resp.data;
        
        return {
            data: data.filter(s => !!s.id).map(s => new Subscription(s)),
            page,
            last_page,
            total
        };
    }

    static getSubscription () {
        if (store.getters.getSubscription) {
            return new Subscription({ ...store.getters.getSubscription });
        }
    
        return null;
    }

    /**
     * Pauses a subscription for a given number of billing cycles
     * @param {number} duration 
     * @returns {Object}
     */
    async pause (duration) {
        const payload = {
            data: JSON.stringify({ duration })
        };

        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/pause` });
        const resp = await client.post(payload);

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        this.instantiate(resp.data);
        store.commit('setSubscription', this);

        return this;
    }

    /**
     * Resumes a paused subscription
     * @returns {Object}
     */
    async resume () {
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/resume` });
        const resp = await client.post({});

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        this.instantiate(resp.data);
        store.commit('setSubscription', this);

        return this;
    }
    
    /**
     * Cancels a subscription
     * @param {string} reason 
     * @returns {object}
     */
    async cancel (reason) {
        const payload = {
            data: JSON.stringify({ cancel_reason: reason })
        };
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/cancel` });
        const resp = await client.post(payload);

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        if (!(resp.data && resp.data.id)) {
            throw new Error('Error refreshing user');
        }

        this.instantiate(resp.data);
        store.commit('setSubscription', this);

        return this;
    }

    /**
     * Uncancels a subscription
     * @param {string} reason 
     * @returns {object}
     */
    async uncancel () {
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/uncancel` });
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        if (!(resp.data && resp.data.id)) {
            throw new Error('Error refreshing user');
        }

        this.instantiate(resp.data);
        store.commit('setSubscription', this);

        return this;
    }

    /**
     * Sends a subscription product change request
     * @param {object} data 
     * @returns {object}
     */
    async changeProduct ({ subscription_change_option_id }) {
        if (!subscription_change_option_id) {
            throw new Error('Missing argument subscription_change_option_id');
        }

        const payload = {
            data: JSON.stringify({ subscription_change_option_id })
        };
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/change-product` });
        const resp = await client.post(payload);

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        return resp;
    }

    /**
     * Fetches a product change request model. If a subscription is returned, that means the product change was delivered
     * and thus the subscription should be refreshed.
     * @param {number || string} id
     * @returns {object || null}
     */
    async getProductChange (id) {
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/product-changes/${id}` })
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200 && resp.status !== 404) {
            throw new Error(resp.status);
        } else if (resp.status === 404) {
            return null;
        }

        if (resp.data?.subscription?.fs_subscription_id === this.fs_subscription_id) {
            this.instantiate(resp.data.subscription);
            store.commit('setSubscription', this);

            return resp.data;
        }

        return resp.data;
    }

    /**
     * Retrieves a FastSpring account management link
     * @returns {object}
     */
    async getFastSpringLink () {
        const client = new HttpClient({ path: '/laravel/rest/user/fslink', query: { subscription_id: this.fs_subscription_id } });
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        if (!(resp.data && resp.data.url)) {
            throw new Error('Error obtaining FastSpring account management link.');
        }

        return resp;
    }

    /**
     * Fetches product upgrade marketing details
     * @param {Array} paths
     * @returns {Object}
     */
    async fetchProductChangeDetails (paths) {
        const client = new HttpClient({ path: '/laravel/rest/products/product-details', query: { paths } });
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        return resp;
    }

    /**
     * Fetches product change details
     * @param {number} id 
     * @returns {object}
     */
    async fetchProductChangeDetailsByMarketingDataId (id) {
        const client = new HttpClient({ path: `/laravel/rest/marketing-data/${id}`});
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        return resp;
    }

    /**
     * Get preview for smart rebilling
     * @param {number} day
     * @returns {Object}
     */
    async fetchSmartRebillPreview (day) {
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/smart-rebill`, query: { d: day } });
        const resp = await client.get();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        } else if (!(resp.data && resp.data)) {
            throw new Error('Missing required data attribute.');
        }

        return resp;
    }

    /**
     * Sets a subscription's billing day of the month
     * @param {number} day
     * @returns {Object}
     */
    async postSmartRebill (previewData) {
        const payload = {
            data: JSON.stringify(previewData)
        };
        const client = new HttpClient({ path: `/laravel/rest/user/subscriptions/${this.id}/smart-rebill` });
        const resp = await client.post(payload);

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        } else if (!(resp.data && resp.data)) {
            throw new Error('Missing required data attribute.');
        }

        this.instantiate(resp.data);
        store.commit('setSubscription', this);

        return resp;
    }

    /**
     * Redeems a subscription last-chance offer.
     * @returns {Object}
     */
    async redeemLastChanceOffer () {
        let payload = {
            path: `/laravel/rest/user/subscriptions/${this.id}/lastchance`
        };

        const client = new HttpClient(payload);
        const resp = await client.post();

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        this.instantiate(resp.data);
        store.commit('setSubscription', this);

        return resp.data;
    }

    /**
     * Redeems a subscription trial cancellation offer.
     * @param {number}
     * @returns {object}
     */
     async redeemTrialCancellationOffer ({ subscription_change_option_id }) {
        const payload = {
            path: `/laravel/rest/user/subscriptions/${this.id}/trialoffer`
        };
        const client = new HttpClient(payload);
        const resp = await client.post({ data: JSON.stringify({ subscription_change_option_id }) });

        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        if (resp.data.subscription) {
            this.instantiate(resp.data.subscription);
            store.commit('setSubscription', this);
        }

        return resp.data;
    }

    /**
     * Returns the current status of the subscription.
     * @returns {object || null}
     */
    get subStatus () {
        const { fs_status, cancel_pending, pause_status } = this;

        if (cancel_pending || fs_status === 'canceled') {
            return {
                type: 'warning',
                text: 'Canceled',
            };
        }

        if (fs_status === 'deactivated') {
            return {
                type: 'danger',
                text: 'Deactivated',
            }
        }

        if (fs_status === 'trial') {
            return {
                type: 'warning',
                text: 'Trial',
            }
        }

        if (pause_status === 'pause_pending') {
            return { 
                type: 'warning',
                text: 'Pause Scheduled',
            };
        }

        if (pause_status === 'paused') {
            return { 
                type: 'warning',
                text: 'Paused',
            };
        }

        if (fs_status && typeof fs_status === 'string') {
            return {
                type: '',
                text: fs_status[0].toUpperCase() + fs_status.substring(1),
            };
        }

        return null;
    }

    /**
     * Returns a boolean indicating whether the subscription can be canceled.
     * @return {boolean}
     */
    get isCancelable () {
        const { fs_status } = this;

        return fs_status === 'active' || fs_status === 'trial' || fs_status === 'paused' || fs_status === 'overdue';
    }

    /**
     * Returns a boolean indicating whether the subscription is technically alive (trial, active, paused, or pause scheduled).
     * @return {boolean}
     */
    get isAlive () {
        const { fs_status } = this;

        return fs_status === 'active' || fs_status === 'trial' || fs_status === 'paused' || fs_status === 'pause_pending' || fs_status === 'overdue';
    }

    /**
     * Returns a string displaying the next event
     * @returns {string}
     */
    get nextDate () {
        const { fs_data, fs_status } = this;

        if (fs_data && fs_data.nextChargeDate) {
            return `${fs_status === 'trial' ? 'Converts to paid on ' : 'Renews on '}` + new Date(this.fs_data.nextChargeDate).toLocaleDateString();
        }

        if (fs_data && fs_data.deactivationDate) {
            const deactivationDate = new Date(this.fs_data.deactivationDate);
            const now = new Date();
            let verb = now.valueOf() > deactivationDate.valueOf() ? 'Deactivated' : 'Deactivating';

            return verb + ' on ' + deactivationDate.toLocaleDateString();
        }

        return '';
    }

    /**
     * Retrieves the product GUI image
     * @returns {string}
     */
    get productImg () {
        if (this.pid) {
            return 'https://antares.sfo2.cdn.digitaloceanspaces.com/product_guis/product_gui_ids/' + this.pid + '.png';
        }
        
        return '';
    }
}